import React, { useState } from 'react';
import './ImageUpload.css'; // Import your CSS

const ImageUpload = (props) => {
  const [image, setImage] = useState(null);
  const fileInputRef = React.createRef();

  const resizeImage = (file, maxSize) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        let { width, height } = image;

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        canvas.width = width;
        canvas.height = height;

        context.drawImage(image, 0, 0, width, height);

        canvas.toBlob(resolve, file.type);
      };
      image.src = URL.createObjectURL(file);
    });
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    // Check the file type
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      alert('请上传JPEG，JPG或PNG格式的图片');
      return;
    }

    const resizedBlob = await resizeImage(file, 4000);
    const resizedImage = new File([resizedBlob], file.name, { type: file.type });

    const reader = new FileReader();
    reader.readAsDataURL(resizedImage);
    reader.onloadend = () => {
      setImage(reader.result);
    };

    props.onImageChange(resizedImage);
  }

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="image-upload">
      <div className="image-container">
        <img src={image || "/assets/page2/page2_upload_btn.png"} alt="Upload" onClick={handleClick} />
      </div>
      <input ref={fileInputRef} type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
    </div>
  );
};

export default ImageUpload;