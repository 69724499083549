import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import './MainPage.css';
import Page1 from './Page1/Page1';
import Page2 from './Page2/Page2';
import Page3 from './Page3/Page3';
import Page4 from './Page4/Page4';

import cloudbase from '@cloudbase/js-sdk';
// import wx from 'weixin-js-sdk'


// 初始化 SDK
const app = cloudbase.init({
  env: 'ai-swapface-7gf8e903d876d296' // 填入您的环境 ID
});

const characters = [
  'baishe',
  'chenxuee',
  'duliniang',
  'hongxiannv',
  'lifengjie',
  'muguiying',
  'sekong',
  'shangyuanfuren',
  'tiannv',
  'xishi',
  'yangguifei'
]

const bgImages = {
  baishe: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/baishe/baishe_bg2.jpg?sign=59fae2cb3d66c92765aa10430d82e41e&t=1708514148',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/baishe/baishe_bg3.jpg?sign=1009a9e70871ff68404d12769a7ac462&t=1708514168'
  },
  chenxuee: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/chenxuee/chenxuee_bg2.jpg?sign=8ca03ed2fbd1f2284f27e88d6e87b88a&t=1708514188',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/chenxuee/chenxuee_bg3.jpg?sign=7c0b15fb0dae179539f5f19cfee200be&t=1708514198'
  },
  duliniang: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/duliniang/duliniang_bg2.jpg?sign=7f82b2d519f3229b568d3b5949684faa&t=1708514228',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/duliniang/duliniang_bg3.jpg?sign=0c77439cb4eaa86f2719b02c5917a38b&t=1708514239'
  },
  hingxiannv: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/hongxiannv/hongxiannv_bg2.jpg?sign=8fdf58b45f666f7b49e537f6419ceeda&t=1708514310',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/hongxiannv/hongxiannv_bg3.jpg?sign=c992ba12910f5540c5fa2babcbd375a1&t=1708514320'
  },
  lifengjie: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/lifengjie/lifengjie_bg2.jpg?sign=f58d68d6fee161ef05fb2a7167230a41&t=1708514345',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/lifengjie/lifengjie_bg3.jpg?sign=1ee895fdd51d9ea79dae2b5a347b5e9d&t=1708514353'
  },
  muguiying: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/muguiying/muguiying_bg2.jpg?sign=34a9e85a43c58add9f7d7d71b4f3a336&t=1708514477',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/muguiying/muguiying_bg3.jpg?sign=9424d33a1e6d110be6f7a9921145c594&t=1708514487'
  },
  sekong: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/sekong/sekong_bg2.jpg?sign=ea14d92ed4be2050dbdc822ab3b9c6e8&t=1708514523',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/sekong/sekong_bg3.jpg?sign=3310d5566d4295fde74c51b98f66fcf2&t=1708514530'
  },
  shangyuanfuren: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/shangyuanfuren/shangyuanfuren_bg2.jpg?sign=bf32dd0b75ea6537b1d0a2d4603bf25c&t=1708514557',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/shangyuanfuren/shangyuanfuren_bg3.jpg?sign=4e1a8e597f145f401be2de6a5b25ada1&t=1708514573'
  },
  tiannv: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/tiannv/tiannv_bg2.jpg?sign=5b32cbd655535e78959d41b028a5e906&t=1708514605',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/tiannv/tiannv_bg3.jpg?sign=b6a23cc3e6bfd778af49bd51f876e11d&t=1708514613'
  },
  xishi: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/xishi/xishi_bg2.jpg?sign=d6b71ea60fb2473890b2bd9296e2cfc1&t=1708514639',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/xishi/xishi_bg3.jpg?sign=a8516b52b36fc4784bf7cb01b1410d7a&t=1708514648'
  },
  yangguifei: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/yangguifei/yangguifei_bg2.jpg?sign=379d8d4f9f575bdcbbd730e19da454dc&t=1708514668',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/yangguifei/yangguifei_bg3.jpg?sign=b6762b9ec4acba7f0bd7e85fbab4fa55&t=1708514686'
  },
  yuji: {
    page2: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/yuji/yuji_bg2.jpg?sign=a494f2160c584936a883444c2d4130b8&t=1708514713',
    page3: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/yuji/yuji_bg3.jpg?sign=4d497ae6b6c509a0868efb9bc9e0a59d&t=1708514720'
  }
};

const auth = app.auth();

async function login() {
  // 调用匿名登录接口
  await auth.anonymousAuthProvider().signIn();
  // 匿名登录成功后，登录状态isAnonymous字段值为true
  const loginState = await auth.getLoginState();
  console.log(loginState.isAnonymousAuth); // true
  console.log(loginState.user); // 匿名用户对象
}

login();

const MainPage = () => {
  const [character, setCharacter] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [videoBg, setVideoBg] = useState(null);
  const [bgImage2, setBgImage2] = useState(null);
  const [bgImage3, setBgImage3] = useState(null);
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    // Clear cache on component mount
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);

  useEffect(() => {
    // Assume characters is defined in this file
    if (characters.length > 0) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      const characterParam = characters[randomIndex];
      console.log('Random character', characterParam);

      setCharacter(characterParam);
      setBgImage2(bgImages[characterParam].page2);
      setBgImage3(bgImages[characterParam].page3);
    } else {
      alert('缺少关键参数，请扫码重试！');
    }
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.onbeforeunload = null;
    }
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  useEffect(() => {
    const checkOrientation = () => {
      if (window.innerHeight < window.innerWidth) {
        document.getElementById('rotateDevice').style.display = 'block';
      } else {
        document.getElementById('rotateDevice').style.display = 'none';
      }
    };
    checkOrientation();
    window.addEventListener("resize", checkOrientation);
    return () => {
      window.removeEventListener("resize", checkOrientation);
    }
  }, []);

  console.log(character);

  return (
    <div>
      <div id="rotateDevice">请旋转手机以获得更好的体验</div>
      <Element name="page1" >
        <Page1 setOrderId={setOrderId}/>
      </Element>

      <Element name="page2">
        <Page2 character={character} bgImage={bgImage2} setUserImage={setUserImage} />
      </Element>

      <Element name="page3">
        <Page3 bgImage={bgImage3} setVideoBg={setVideoBg} />
      </Element>

      <Element name="page4">
        <Page4 character={character} bgImage={bgImage3} userImage={userImage} videoBg={videoBg} orderId={orderId}/>
      </Element>
    </div>
  );
};

export default MainPage;