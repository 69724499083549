// Page2.js
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './Page2.css';
import cloudbase from '@cloudbase/js-sdk';
import ImageUpload from './ImageUpload';

// 初始化 SDK
const app = cloudbase.init({
  env: 'ai-swapface-7gf8e903d876d296' // 填入您的环境 ID
});


const Page2 = (props) => {
  const preventDefault = e => e.preventDefault();
  window.addEventListener('touchmove', preventDefault, { passive: false });

  const character = props.character;
  const setUserImage = props.setUserImage;
  const bgImage = props.bgImage;
  console.log('bgImage', character, bgImage);

  const [cloudPath, setCloudPath] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);

  const handleFileChange = (file) => {
    setUploadFile(file);
    // Extract the file extension
    const extension = file.name.split('.').pop();
    // Create a timestamp
    const timestamp = new Date().getTime();
    const cloudPath = `userUploadPhotos/${timestamp}.${extension}`;
    setCloudPath(cloudPath);
  };

  const handleButtonClick = () => {
    // console.log(uploadFile); // log the file object

    // Upload the file
    uploadFileAsync();
  };

  async function uploadFileAsync() {
    try {
      // Upload the file
      const res = await app.uploadFile({
        cloudPath: cloudPath,
        filePath: uploadFile
      });

      // // Log the file ID
      // console.log(res.fileID);
      // console.log(res.download_url);
      setUserImage(res.download_url);
    } catch (error) {
      console.error("Failed to upload file:", error);
    }
  }

  return (
    <div className="page page2" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="upload-image">
        <ImageUpload onImageChange={handleFileChange} />
      </div>
      {/* <Link to="page3" smooth={true} duration={1600} className='link-container'>
        <img src="/assets/page2/page2_btn.png" onClick={handleButtonClick} alt="upload" className="next-btn" />
      </Link> */}
      {uploadFile && (
        <Link to="page3" smooth={true} duration={1600} className='link-container'>
          <img src="/assets/page2/page2_btn.png" onClick={handleButtonClick} alt="upload" className="next-btn" />
        </Link>
      )}
    </div>
  );
};

export default Page2;