import React from 'react';
import './Hint.css';

const Hint = () => (
  <div className="hint">
    <div className="hint-container">
      <img src="/assets/pageShow/video_download_tag.png" alt="hint" className='bg' />
    </div>
  </div>
);

export default Hint;