import React from 'react';
import './Loading.css';

const Loading = () => (
  <div className="loading">
    <div className="gif-container">
      <img src="/assets/page4/loading.gif" alt="loading" className='gif' />
    </div>
    <div className="dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  </div>
);

export default Loading;