// Page1.js
import React, { useEffect } from 'react';
import { Link } from 'react-scroll';
import './Page1.css';

// Function to get or generate a unique identifier
function getUniqueIdentifier() {
  let uniqueId = getCookie('uniqueId');
  if (!uniqueId) {
    uniqueId = generateUniqueId();
    setCookie('uniqueId', uniqueId, 365); // Expires in 1 year
  }
  return uniqueId;
}

function generateUniqueId() {
  const timestamp = Date.now(); // Get the current timestamp
  const randomString = Math.random().toString(36).substr(2, 16); // Generate a random string
  return `id-${randomString}-${timestamp}`; // Combine them to form the unique ID
}

function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const Page1 = (props) => {
  const preventDefault = e => e.preventDefault();
  window.addEventListener('touchmove', preventDefault, { passive: false });

  useEffect(() => {
    const userId = getUniqueIdentifier();
    console.log("User ID:", userId);

    console.log("Cookies:", document.cookie);
    console.log("Cookie Enabled:", navigator.cookieEnabled);

    // Check if cookies are enabled
    if (!navigator.cookieEnabled) {
      alert("请启用Cookie以继续使用本网站。");
    }

    // Detect if the user is in private/incognito mode
    const isIncognito = () => {
      try {
        window.localStorage.setItem('test', 'test');
        window.localStorage.removeItem('test');
        return false;
      } catch (e) {
        return true;
      }
    };

    if (isIncognito()) {
      alert("您正在使用隐私模式，请注意某些功能可能无法正常工作。");
    }
  }, []);

  return (
    <div id='page1' className="page page1">
      <Link to="page2" smooth={true} duration={1600} className='link-container'>
        <img src="/assets/page1/page1_btn.png" alt="next" className="next-btn" />
      </Link>
    </div>
  );
};

export default Page1;