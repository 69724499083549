import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import MainPage from './components/MainPage/MainPage';
import VideoShowPage from './components/VideoShowPage/VideoShowPage';

const NotFound = () => <div>Not found</div>;

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/video" element={<VideoShowPage />} />
    </Routes>
  </Router>
);

export default App;