// VideoShowPage.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './VideoShowPage.css';
import Hint from './Hint';
// import wx from 'weixin-js-sdk'

const bgImages = {
  baishe: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/baishe/baishe_result_bg.png?sign=1ccf0f390791abfd9969819892d0ce38&t=1708587772'
  },
  chenxuee: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/chenxuee/chenxuee_result_bg.png?sign=60449c777e8f29cee130f728891009e5&t=1708587786'
  },
  duliniang: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/duliniang/duliniang_result_bg.png?sign=74cdd59f67072b1bd784c92affce1303&t=1708587989'
  },
  hingxiannv: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/hongxiannv/hongxiannv_result_bg.png?sign=46d3ce57429541af4c7decda70e5ed2f&t=1708588009'
  },
  lifengjie: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/lifengjie/lifengjie_result_bg.png?sign=c1cbad72d5c90a87631404a5a5626cb2&t=1708588023'
  },
  muguiying: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/muguiying/muguiying_result_bg.png?sign=51b135d834d7c0a57d1ec0226b22bdf2&t=1708588043'
  },
  sekong: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/sekong/sekong_result_bg.png?sign=b928fdd6fae2b3b9ee1edaea40413689&t=1708588055'
  },
  shangyuanfuren: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/shangyuanfuren/shangyuanfuren_result_bg.png?sign=797b1ad29b81287acc0919ee27e74e34&t=1708588078'
  },
  tiannv: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/tiannv/tiannv_result_bg.png?sign=08eb2ce55ac0b33a8caf4edfcf5600ef&t=1708588102'
  },
  xishi: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/xishi/xishi_result_bg.png?sign=aa30b04dcc9015363e647611eeb82512&t=1708588146'
  },
  yangguifei: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/yangguifei/yangguifei_result_bg.png?sign=97169d0de4abd9d6a788ed42eed8fde5&t=1708588173'
  },
  yuji: {
    show: 'https://6169-ai-swapface-7gf8e903d876d296-1314635850.tcb.qcloud.la/websiteUi/yuji/yuji_result_bg.png?sign=594c5d64c36625a9c9c3ddd533f8a838&t=1708588231'
  }
};

const VideoShowPage = () => {
  // const preventDefault = e => e.preventDefault();
  // window.addEventListener('touchmove', preventDefault, { passive: false });

  const [videoLink, setVideoLink] = useState(null);
  const [character, setCharacter] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [secondModalIsOpen, setSecondModalIsOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const linkParam = params.get('link');
    const characterParam = params.get('character');
    if (linkParam && characterParam) {
      console.log('linkParam', linkParam);
      setVideoLink(linkParam);
      console.log('characterParam', characterParam);
      setCharacter(characterParam);
    } else {
      alert('未找到视频源，请扫码重试！');
    }
  }, []);

  // useEffect(() => {
  //   wx.showMenuItems({
  //     menuList: ['menuItem:openWithSafari', 'menuItem:originPage', 'menuItem:openWithQQBrowser', 'menuItem:share:appMessage',]
  //   });
  // }, []);

  const downloadVideo = async (videoLink) => {
    const videoUrl = videoLink;
    try {
      const response = await fetch(videoUrl);
      if (response.status !== 200) {
        throw new Error('Error:', response.statusText);
      } else {
        setSecondModalIsOpen(true);
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = blobUrl;
      a.download = 'video.mp4';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    } catch (e) {
      console.error('Error:', e);
      alert('Error:' + e);
    }
  }

  function closeModal() {
    setSecondModalIsOpen(false);
  }

  const isWeChat = /micromessenger/i.test(navigator.userAgent);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const videoRef = React.useRef(null);
  const isAndroid = /Android/.test(navigator.userAgent);

  // const test = () => {
  //   console.log('test');
  // }
  const test = () => {
    console.log('test');
    if (isAndroid) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) { /* Firefox */
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) { /* IE/Edge */
        videoRef.current.msRequestFullscreen();
      }
      videoRef.current.play();
    }
  };

  if (!videoLink) {
    return null;
  }

  const handleModalTest = () => {
    setModalIsOpen(true);
    setTimeout(() => {
      setModalIsOpen(false);
    }, 10000);
  };

  return (
    <div className="page video-show-page">
      <div className='bg-wrapper'>
        <img src={bgImages[character].show} alt="background" className="bg" />
      </div>
      {/* <video className='video' autoPlay loop playsInline> */}
      {/* <div className='video-container' onClick={test}>
        {isWeChat || isIOS ? (
          <video className='video' controls loop playsInline>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <video className='video' controls loop>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div> */}
      <div className='video-container' onClick={test}>
        {isWeChat || isIOS ? (
          <video ref={videoRef} className='video' controls loop playsInline>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <video ref={videoRef} className='video' controls loop>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      {isWeChat ? (
        <div className='download-container'>
          <img src="/assets/pageShow/pageShow_btn.png" alt="download" className="download-btn" onClick={handleModalTest} />
        </div>
      ) : (
        <div className='download-container'>
          <img src="/assets/pageShow/pageShow_btn.png" alt="download" className="download-btn-out" onClick={() => downloadVideo(videoLink)} />
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        overlayClassName="PS_ReactModal__Overlay"
        className="PS_ReactModal__Content"
      >
        <Hint />
      </Modal>

      <Modal
        isOpen={secondModalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="PS_ReactModal__Overlay2"
        className="PS_ReactModal__Content2"
      >
        <h2>下载即将开始...</h2>
        <p>视频文件将下载至"下载"文件夹</p>
        <button onClick={closeModal}>我已知晓</button>
      </Modal>
    </div>
  );
};

export default VideoShowPage;