// Page4.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Page4.css';
import Modal from 'react-modal';
import Loading from './Loading';
// import wx from 'weixin-js-sdk';
import cloudbase from '@cloudbase/js-sdk';

// 初始化 SDK
const app = cloudbase.init({
  env: 'ai-swapface-7gf8e903d876d296' // 填入您的环境 ID
});

const db = app.database(); // Initialize the database

const images = [
  {
    unselectedSrc: "/assets/page4/selection1.png",
    selectedSrc: "/assets/page4/selection1_selected.png",
    style: "original"
  },
  {
    unselectedSrc: "/assets/page4/selection2.png",
    selectedSrc: "/assets/page4/selection2_selected.png",
    style: "3d"
  },
  {
    unselectedSrc: "/assets/page4/selection3.png",
    selectedSrc: "/assets/page4/selection3_selected.png",
    style: "handdrawn"
  },
];

// Function to get or generate a unique identifier
function getUniqueIdentifier() {
  let uniqueId = getCookie('uniqueId');
  if (!uniqueId) {
    return null;
  }
  return uniqueId;
}

function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const Page4 = (props) => {
  const preventDefault = e => e.preventDefault();
  window.addEventListener('touchmove', preventDefault, { passive: false });

  const character = props.character;
  const userImage = props.userImage;
  const bgImage = props.bgImage;
  const videoBg = props.videoBg;
  // const orderId = props.orderId;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [background, setBackground] = useState(null);

  const handleImageClick = (index) => {
    setBackground(index);
    console.log("settings", character, userImage, videoBg, images[index].style);
  }

  // const submitAiSwapFace = async (character, videoBg, userImage, style, uniqueId) => {
  //   try {
  //     const res = await app.callFunction({
  //       name: 'aiSwapFace',
  //       data: {
  //         character: character,
  //         background: videoBg,
  //         style: style,
  //         userImage: userImage,
  //         uniqueId: uniqueId // Include the unique ID
  //       }
  //     });
  //     console.log("开始调用", res);
  //     if (res.result) {
  //       return res.result;
  //     }
  //   } catch (error) {
  //     console.error("调用失败", error);
  //   }
  // }

  // const getAsyncJobRes = async (jobId) => {
  //   try {
  //     const res = await app.callFunction({
  //       name: 'fetchResult',
  //       data: {
  //         jobId: jobId,
  //       }
  //     });
  //     console.log("开始调用", res);
  //     if (res.result !== "PROCESSING" && res.result !== "WRITTING") {
  //       let jsonObject = JSON.parse(res.result);
  //       let videoUrl = jsonObject.videoUrl;
  //       return videoUrl;
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error("调用失败", error);
  //   }
  // }

  const addObjectToDatabase = async (data) => {
    try {
      const res = await db.collection('generationSet').add(data);
      console.log("数据添加成功", res);
      return true; // Indicate success
    } catch (error) {
      console.error("数据添加失败", error);
      return false; // Indicate failure
    }
  }

  const fetchGeneratedVideoUrl = async (uniqueId, timestamp) => {
    try {
      const res = await db.collection('generationSet').where({
        uniqueId: uniqueId,
        timestamp: timestamp
      })
      .orderBy("timestamp", "desc")
      .limit(1)
      .get();

      console.log("This is res data length", res.data.length);
      console.log("This is res data", res.data);
      
      if (res.data && res.data.length > 0) {
        return res.data[0].generatedVideoUrl;
      }
      return null;
    } catch (error) {
      console.error("获取生成视频URL失败", error);
      return null;
    }
  }

  const handleSubmit = async () => {
    setModalIsOpen(true);
    const uniqueId = getUniqueIdentifier(); // Get the unique identifier
    const timestamp = Date.now(); // Get the timestamp

    console.log("uniqueId", uniqueId);

    // Add object to the database
    const data = {
      uniqueId: uniqueId,
      timestamp: timestamp,
      character: character,
      background: videoBg,
      style: images[background].style,
      userImage: userImage,
      generatedVideoUrl: null,
      isUploaded: null,
      videoTaskId: null,
      isGenerated: null
    };
    
    const isSuccess = await addObjectToDatabase(data);

    if (isSuccess) {
      console.log("Object added successfully");

      // Set up interval to check for generated video URL
      const intervalId = setInterval(async () => {
        const generatedVideoUrl = await fetchGeneratedVideoUrl(uniqueId, timestamp);
        if (generatedVideoUrl) {
          clearInterval(intervalId);
          setModalIsOpen(false);
          navigateToVideo(generatedVideoUrl, character);
          // wx.miniProgram.postMessage({
          //   data: {
          //     videoUrl: generatedVideoUrl,
          //   }
          // });
        }
      }, 5000); // Check every 5 seconds
    } else {
      console.log("Failed to add object");
      // Handle the failure case
    }

    // setTimeout(() => {
    //   setModalIsOpen(false);
    // }, 3000);

  };

  const navigate = useNavigate();

  const navigateToVideo = (link, character) => {
    console.log("navigateToVideo", link);
    navigate('/video?link=' + encodeURIComponent(link) + '&character=' + encodeURIComponent(character));
  };

  return (
    <div id='page4' className="page page4" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className='image-container'>
        <img
          key={0}
          alt={"1"}
          onClick={() => {
            handleImageClick(0)
          }}
          src={background === 0 ? images[0].selectedSrc : images[0].unselectedSrc}
        />
        <img
          key={1}
          alt={"2"}
          onClick={() => {
            handleImageClick(1)
          }}
          src={background === 1 ? images[1].selectedSrc : images[1].unselectedSrc}
        />
        <img
          key={2}
          alt={"3"}
          onClick={() => {
            handleImageClick(2)
          }}
          src={background === 2 ? images[2].selectedSrc : images[2].unselectedSrc}
        />
      </div>
      <div>
        {background !== null && userImage !== null && videoBg !== null && (
          <div className='submit-container'>
            <img src="/assets/page4/page4_btn.png" onClick={handleSubmit} alt="upload" className="submit-btn" />
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          overlayClassName="P4_ReactModal__Overlay"
          className="P4_ReactModal__Content"
        >
          <Loading />
        </Modal>
      </div>
    </div>
  );
};

export default Page4;