// Page3.js
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './Page3.css';


const images = [
  {
    unselectedSrc: "/assets/page3/selection1.png",
    selectedSrc: "/assets/page3/selection1_selected.png",
    style: "cyber theater"
  },
  {
    unselectedSrc: "/assets/page3/selection2.png",
    selectedSrc: "/assets/page3/selection2_selected.png",
    style: "zen"
  },
  {
    unselectedSrc: "/assets/page3/selection3.png",
    selectedSrc: "/assets/page3/selection3_selected.png",
    style: "hologram"
  },
  {
    unselectedSrc: "/assets/page3/selection4.png",
    selectedSrc: "/assets/page3/selection4_selected.png",
    style: "mirage"
  }
]

const Page3 = (props) => {
  const preventDefault = e => e.preventDefault();
  window.addEventListener('touchmove', preventDefault, { passive: false });

  const setVideoBg = props.setVideoBg;
  const bgImage = props.bgImage;

  const [background, setBackground] = useState(null);

  const handleImageClick = (index) => {
    setBackground(index);
    console.log("演出风格", images[index].style);
  }

  const handleClick = () => {
    setVideoBg(images[background].style);
  };

  return (
    <div id='page3' className="page page3" style={{ backgroundImage: `url(${bgImage})` }}>
      <div className='image-container'>
        <img
          key={0}
          alt={"1"}
          onClick={() => {
            handleImageClick(0)
          }}
          src={background === 0 ? images[0].selectedSrc : images[0].unselectedSrc}
        />
        <img
          key={1}
          alt={"2"}
          onClick={() => {
            handleImageClick(1)
          }}
          src={background === 1 ? images[1].selectedSrc : images[1].unselectedSrc}
        />
        <img
          key={2}
          alt={"3"}
          onClick={() => {
            handleImageClick(2)
          }}
          src={background === 2 ? images[2].selectedSrc : images[2].unselectedSrc}
        />
        <img
          key={3}
          alt={"4"}
          onClick={() => {
            handleImageClick(3)
          }}
          src={background === 3 ? images[3].selectedSrc : images[3].unselectedSrc}
        />
      </div>
      {background !== null && (
        <Link to="page4" smooth={true} duration={1600} className='link-container'>
          <img src="/assets/page3/page3_btn.png" onClick={handleClick} alt="upload" className="next-btn" />
        </Link>
      )}
    </div>
  );
};

export default Page3;